import {Topbar} from "../../../application/layout";
import React, {useEffect, useState} from "react";
import { Box, Button, Chip, FormControl, Grid, InputAdornment, MenuItem, Select, Slider, Stack, TextField, Typography} from "@mui/material";
import {MairieCard} from "../../../application/components/MairieCard";
import {useNavigate} from "react-router-dom";
import {useMediaQuery, useSessionStorage} from "usehooks-ts";
import dayjs from "dayjs";
import {BREAKPOINT_TABLETTE} from "../../../constant";
import { LocationOn } from "@mui/icons-material";
import { CustomLoading } from "../../../application/components/LazyLoading/LazyLoading";
import { handleCheckBusy, handleUpSertBusy } from "../../../helpers/busy.helper";
import { Popup } from "../../../application/components/Popup/Popup";
import { handleGetBusyDate } from "../../../helpers/calendar.helper";
import { getMoreFilterdMairies, getFilterdMairies, useMairieStore } from "../../../application/store/mairie.store";

const SearchPage = () => {
    const  filteredMairies  = useMairieStore((state) => state.filteredMairies);
    const  loading  = useMairieStore((state) => state.loading);
    const [rows, setRows] = useState<any[]>([]);
    const [radius, setRadius] = useState<number>(50);
    const [loadingMore, setLoadingMore] = useState<boolean>(false);
    const [selected, setSelected] =  useState({} as any);
    const [alert, setAlert] = useState(false);
    const [selectedCodePostal, setSelectedCodePostal] =  useState<any>(null);
    const navigate = useNavigate();
    const [busyDate, setBusyDate] = useState({} as any);
    const tablette = useMediaQuery(BREAKPOINT_TABLETTE);
    const [filterBy, setFilterBy] = useState<string>('date');
    const [session, setSessionValue] = useSessionStorage('session', {} as any);

    const handleFilterChange = (event) => {
        setFilterBy(event.target.value)
        setRows([])
        const input = {
            ...session,
            startDate: dayjs().format('YYYY-MM-DD'),
            endDate: dayjs().add(12, 'month').format('YYYY-MM-DD'),
            ville : {
                coordinates: session?.ville ? session?.ville?.coordinates : [],
                zip: session.ville.code
            },
            filterBy: event.target.value
        }
        getFilterdMairies(input);
    }

    const handleOnDateSelect = async(calendar, data, mairie) => {
        const [hours, minutes] = data.hour.split(':');
        const date = dayjs(calendar).hour(hours).minute(minutes);
        setSessionValue(prev => ({
            ...prev,
            ville: mairie,
            type: data.type,
            bureau: data.idAgenda,
            heureRdv: dayjs(calendar).hour(hours).minute(minutes),
            duration: data.duration,
            city: `${selectedCodePostal?.codesPostaux}, ${selectedCodePostal?.nom}`
        }));

        const isDispo = await handleCheckBusy({
            mairie: mairie._id,
            agenda: data.idAgenda,
            dateRdv: dayjs(calendar).format('DD/MM/YYYY'),
            heureRdv: dayjs(date).utc().local().format().split('+')[0],
            sessionId: session?.id,
            duration:  Number(data.duration)*Number(session.nombrePersonne),
        })

        if(isDispo){
            const res = await handleUpSertBusy({
                mairie: mairie._id,
                agenda: data.idAgenda,
                dateRdv: dayjs(calendar).format('DD/MM/YYYY'),
                heureRdv: dayjs(date).utc().local().format().split('+')[0],
                sessionId: session?.id,
                duration:  Number(data.duration)*Number(session.nombrePersonne),
            })
            if(res?.success){
                navigate('/confirmation-rdv')
            }
        }else{
            setAlert(true);
        }
    }

    const handleShowMore = async () => {
        const idMairies = rows.map(mairie => mairie?._id);
        const input = {
            ...session,
            startDate: dayjs().format('YYYY-MM-DD'),
            endDate: dayjs().add(12, 'month').format('YYYY-MM-DD'),
            radius: radius+50,
            ville : {
                coordinates: session?.ville ? session?.ville?.coordinates : [],
                zip: session.ville.code
            },
            idMairies: idMairies
        }
        setLoadingMore(true);
        await getMoreFilterdMairies(input, setRows);
        setLoadingMore(false)
        setRadius(prevState => prevState+50)
    }

    const handleCloseAlert = () => {
        setAlert(false);
    }

    // const handleChangeKm = (e) => {
    //     const input = {
    //         ...session,
    //         startDate: dayjs().format('YYYY-MM-DD'),
    //         endDate: dayjs().add(12, 'month').format('YYYY-MM-DD'),
    //         radius: e.target.value,
    //         ville : {
    //             coordinates: session?.ville ? session?.ville?.coordinates : [],
    //             zip: session.ville.code
    //         },
    //         filterBy: filterBy
    //     }
    //     setRows([])
    //     getFilterdMairies(input);
    // }
    useEffect(() => {
        if(filteredMairies?.length > 0){
            setRows(filteredMairies)
        }
    },[filteredMairies])

    useEffect(() => {
        if (!session?.typeDossier) {
            navigate('/home')
        } else {
            const typeDossier = session.motif
            setSelected(typeDossier);
            setSelectedCodePostal(session.villeUser)
        }
    }, [navigate, session.motif, session.villeUser]);

    useEffect(() => {
        handleGetBusyDate(setBusyDate);
    }, []);
   
    return <>
        {selectedCodePostal && <Box sx={{width: '100%'}}>
            <Topbar variant={"condensed"}/>
            <Stack marginX={tablette ? 4 : 18}>
                <Grid container marginTop={15}>
                    <Grid item xs={4}>
                        <Typography variant={"h4"}>Résultats</Typography>
                    </Grid>
                    <Grid item xs={tablette ? 12 : 4} marginTop={tablette ? 2 : 0}>
                        <Stack flexDirection={`${tablette ? 'column' : 'row'}`} gap={2} justifyContent={"end"}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, padding: 1.3 }} className={"border-2 border-gray-300 rounded-lg"}>
                                <Chip label={selected?.label} />
                            </Box>
                            <Stack width={'100%'}>
                                <TextField
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            paddingRight: "10px!important",
                                        },
                                    }}
                                    defaultValue={`${selectedCodePostal?.codesPostaux}, ${selectedCodePostal?.nom} `}
                                    disabled={true}
                                    placeholder={"Code postal*"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOn/>
                                            </InputAdornment>
                                        )}
                                    }
                                />
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={tablette ? 12 : 4} marginTop={tablette ? 2 : 0}>
                        <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
                            <Typography variant={"subtitle1"} marginLeft={2}><span className={'font-bold'}>Filtre</span></Typography>
                            <FormControl fullWidth>
                                <Select
                                    value={filterBy}
                                    onChange={handleFilterChange}
                                    renderValue={(selected: any) => {
                                        return <span>
                                            {selected === 'date' ? 'Le plus tôt (Date)' : 'Le plus proche (Km)' }
                                        </span>
                                    }}
                                >
                                    <MenuItem value={'date'}>Le plus tôt (Date)</MenuItem>
                                    <MenuItem value={'distance'}>Le plus proche (Km)</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    </Grid>
                    {/* <Grid item xs={12} marginTop={2}>
                        <Typography variant={"subtitle1"} className='text-center'><span className={'font-bold'}>Dans un périmètre de :</span></Typography>
                        <Box sx={{ width: 300, margin:'auto' }}>
                            <Slider
                                aria-label="Dans un périmètre de :"
                                defaultValue={60}
                                getAriaValueText={(value) => `${value} Km`}
                                valueLabelDisplay="auto"
                                onChange={handleChangeKm}
                                step={40}
                                marks={getMarks()}
                                min={60}
                                max={300}
                            />
                        </Box>
                    </Grid> */}
                </Grid>
            
                <Stack gap={5} marginTop={5} className="flex justify-center">
                    {!loading && rows && rows.map((mairie, key) => (
                        <MairieCard
                        selected={selected}
                        item={mairie}
                        key={key}
                        onDateSelect={(calendar, hour) => handleOnDateSelect(calendar, hour, mairie)}
                        busyDate={busyDate}
                        />
                    ))}
                    {!loading && rows?.length === 0 && <p className='text-center bold'>Aucun créneau disponible sur le périmètre de {radius} km</p>}
                    {loading && <CustomLoading size={100} /> }
                    {loadingMore && <CustomLoading size={100} /> }
                </Stack>
                <Stack justifyContent={"center"} alignItems={"center"} marginY={5}>
                    <Button variant={"outlined"} color={"secondary"} sx={{width: 400}} size={"large"} onClick={()=>handleShowMore()}>Afficher plus de résultats</Button>
                </Stack>
            </Stack>
            <Popup message="Désolé, le créneau horaire sélectionné vient d'être réservé par un autre utilisateur." type="error" open={alert} handleClose={handleCloseAlert} />
        </Box>}
    </>
}

export default SearchPage;
