import NODE_API from "./axios.helper";

export const handleUpSertBusy = async(data) => {
    try {
        const res = NODE_API.post('/busy',{
            mairie: data.mairie,
            hourRdv: data.heureRdv,
            dateRdv: data.dateRdv,
            agenda: data.agenda,
            duration: Number(data.duration),
            sessionId: data.sessionId
        });
        
        return res;
    } catch (error) {
        console.log(error);
    }
}

export const handleCheckBusy = async(data) => {
    try{
        const res = await NODE_API.post('/busy/query',{
            mairie: data.mairie,
            hourRdv: data.heureRdv,
            dateRdv: data.dateRdv,
            agenda: data.agenda,
            duration: Number(data.duration),
            sessionId: data.sessionId
        })

        if(res?.success){
            return res
        }
        return null;
    }catch (error) {
        console.log(error);
    }
}
