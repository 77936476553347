import NODE_API from "./axios.helper";

export const handleAddgenda = async (data) => {
    try {
        const res = NODE_API.post("/agenda", {
            mairie: data.mairie,
            name: data.name,
            motifs: data.motifs,
            mairieHours: data.mairieHours,
            defaultHours: data.defaultHours,
            checkedExceptionHours: data.checkedExceptionHours,
            checkedCustomHours: data.checkedCustomHours,
            defaultShow: data.defaultShow,
            excludeDefaultHours: data.excludeDefaultHours,
            customHours: data.customHours,
            includeCustomHours: data.includeCustomHours,
            excludeCustomHours: data.excludeCustomHours,
            exceptionHours: data.exceptionHours,
            includeExceptionHours: data.includeExceptionHours,
            daysExceptions: data.daysExceptions,
            busyDays: data.busyDays,
            access: data.access,
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const handleUpdateAgenda = async (data) => {
    try {
        const res = NODE_API.put(`/agenda/${data?._id}`, {
            mairie: data.mairie,
            name: data.name,
            motifs: data.motifs,
            mairieHours: data.mairieHours,
            defaultHours: data.defaultHours,
            checkedExceptionHours: data.checkedExceptionHours,
            checkedCustomHours: data.checkedCustomHours,
            defaultShow: data.defaultShow,
            excludeDefaultHours: data.excludeDefaultHours,
            customHours: data.customHours,
            includeCustomHours: data.includeCustomHours,
            excludeCustomHours: data.excludeCustomHours,
            exceptionHours: data.exceptionHours,
            includeExceptionHours: data.includeExceptionHours,
            daysExceptions: data.daysExceptions,
            busyDays: data.busyDays,
            exceptionDayHour: data.exceptionDayHour,
            access: data.access,
        });
        return res;
    } catch (error) {
        console.log(error);
    }
};

export const handleDeleteAgenda = async (data) => {
    try {
        const res = NODE_API.delete(`/agenda/${data?._id}`);
        return res;
    } catch (error) {
        console.log(error);
    }
};
