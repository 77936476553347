import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./Modal.style.scss";
import { useEffect, useState } from "react";
import {
    handleGetAppointment,
    handleUpdateAppointment,
} from "../../../helpers/appointment.helper";
import { CustomDialog } from "../CustomDialog";
import { ViewAlert } from "./view/Alert";
import dayjs from "dayjs";
import { handleAddHistories } from "../../../helpers/histories.helper";
import {
    handleCheckBusy,
    handleUpSertBusy,
} from "../../../helpers/busy.helper";
import { useSessionStorage } from "usehooks-ts";
import {
    handleDeleteAppointment,
    handlePostAppointment,
} from "../../../helpers/ants.helper";
import { handleGetMairieBySlug } from "../../../helpers/mairie.helper";
import { LoadingButton } from "@mui/lab";

const ModalModifyRdv = ({
    open,
    handleClose,
    appointmentId,
    heureRdvDispo,
    room,
    mairieName,
    mairieId,
}) => {
    const [openAlert, setOpenAlert] = useState<any>(null);

    const [session, setSessionValue] = useSessionStorage("session", {} as any);
    const [appointment, setAppointment] = useState<any>(null);
    const [isAlreadyDispo, setAlreadyDispo] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const getAppointment = async (appointmentId) => {
        const res = await handleGetAppointment({ id: appointmentId });
        if (res?.success) {
            setAppointment(res?.data);
        }
    };

    useEffect(() => {
        getAppointment(appointmentId);
    }, [appointmentId]);

    const handleConfirm = async () => {
        setLoading(true);
        const input = {
            mairie: mairieId,
            agenda: room,
            dateRdv: dayjs(heureRdvDispo).format("DD/MM/YYYY"),
            heureRdv: heureRdvDispo,
            sessionId: session?.id,
            duration: appointment?.dureeRdv,
        };
        const isDispo = await handleCheckBusy({ ...input });

        if (isDispo && room) {
            const resBusy = await handleUpSertBusy({ ...input });
            const mairie = await handleGetMairieBySlug(appointment.mairie);

            if (resBusy?.success) {
                for (const person of appointment?.concernedPerson) {
                    await handleDeleteAppointment({
                        ants: person?.ants,
                        meeting_point: mairie?.name,
                        meeting_point_id: mairie?._id,
                        appointment_date: appointment?.heureRdv?.replace(
                            "T",
                            " "
                        ),
                    });
                }

                const res = await handleUpdateAppointment({
                    _id: appointment._id,
                    heureRdv: heureRdvDispo,
                    room: room,
                });
                if (res?.success) {
                    await handlePostAppointment({
                        ...res?.data,
                        meeting_point: mairie.name,
                        meeting_point_id: mairie._id,
                    });
                    const result = await handleAddHistories({
                        phoneNumber: appointment.phoneNumber,
                        appointmentId: appointment._id,
                        status: "PENDING",
                        mairie: appointment.mairie,
                        heureRdv: heureRdvDispo,
                        typeDossier: appointment.typeDossier,
                        nombrePersonne: appointment.nombrePersonne,
                        usager: appointment.usager,
                    });
                    if (result.success) {
                        setOpenAlert(true);
                    }
                }
            }
        } else {
            setAlreadyDispo(false);
        }
        setLoading(false);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                {appointment && isAlreadyDispo ? (
                    <Box className="box-ants">
                        <Typography
                            id="modal-modal-title"
                            className="bold"
                            variant="h6"
                        >
                            Modifier la date et l'heure de votre rendez-vous à
                            la {mairieName}
                        </Typography>
                        <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2, textTransform: "capitalize" }}
                        >
                            Votre rendez-vous initial:{" "}
                            {dayjs(appointment.heureRdv)
                                .locale("fr-FR")
                                .format("dddd DD MMMM")}{" "}
                            de{" "}
                            {dayjs(appointment.heureRdv)
                                .locale("fr-FR")
                                .format("HH:mm")}
                        </Typography>
                        <Typography
                            id="modal-modal-description"
                            sx={{ textTransform: "capitalize" }}
                        >
                            Votre nouveau rendez-vous:{" "}
                            {dayjs(heureRdvDispo)
                                .locale("fr-FR")
                                .format("dddd DD MMMM")}{" "}
                            de{" "}
                            {dayjs(heureRdvDispo)
                                .locale("fr-FR")
                                .format("HH:mm")}
                        </Typography>
                        <Box className="box-btn">
                            <LoadingButton
                                loading={loading}
                                className="w-full btn-next"
                                variant={"contained"}
                                color={"primary"}
                                onClick={handleConfirm}
                            >
                                Je confirme ma demande de modification de la
                                date de rendez-vous
                            </LoadingButton>
                        </Box>
                        <p>
                            * Votre rendez-vous initial sera automatiquement
                            annulé et remplacé par le nouveau
                        </p>
                    </Box>
                ) : (
                    <Box className="box-ants">
                        <Typography
                            id="modal-modal-title"
                            className="bold"
                            variant="h6"
                        >
                            Malheureusement quelqu'un a été plus rapide et le
                            rendez-vous{" "}
                            {dayjs(heureRdvDispo)
                                .locale("fr-FR")
                                .format("dddd DD MMMM")}{" "}
                            de{" "}
                            {dayjs(heureRdvDispo)
                                .locale("fr-FR")
                                .format("HH:mm")}{" "}
                            n'est plus disponible.
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Votre rendez-vous le{" "}
                            {dayjs(appointment?.heureRdv)
                                .locale("fr-FR")
                                .format("dddd DD MMMM")}{" "}
                            de{" "}
                            {dayjs(appointment?.heureRdv)
                                .locale("fr-FR")
                                .format("HH:mm")}{" "}
                            est toujours d'actualité
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Nous ne manquerons pas de vous informer dès qu'une
                            nouvelle opportunité se présentera. A bientôt.
                        </Typography>
                    </Box>
                )}
                <CustomDialog
                    setOpen={setOpenAlert}
                    open={openAlert}
                    onClose={handleCloseAlert}
                    component={<ViewAlert />}
                />
            </>
        </Modal>
    );
};

export default ModalModifyRdv;
