import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Box,
    Button,
    Autocomplete,
    FormControl,
    FormControlLabel,
    InputAdornment,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    FormGroup,
    Checkbox,
    Grid,
    TableCell,
    TableRow,
    TableHead,
    Table,
    TableContainer,
    TableBody,
    Alert,
} from "@mui/material";
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import { useEffect, useRef, useState, useMemo } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { CalendarWeek } from "../../../../../../application/components/CalendarWeek/CalendarWeek";
import {
    initialCalendar,
    initialCalendarCustom,
} from "../../../../../../infrastructure/data/initialCalendar";
import { SelectMultiple } from "../../../../../../application/components/select-multiple/select-multiple";
import remove from "lodash/remove";
import xor from "lodash/xor";
import {
    handleAddgenda,
    handleUpdateAgenda,
} from "../../../../../../helpers/agenda";
import { TableHourType } from "../partials/TableHourType";
import { DeleteIcon } from "../../../../../../application/common/icon";
import {
    refetchListAgendas,
    useMairieStore,
} from "../../../../../../application/store/mairie.store";
import { useAuthStore } from "../../../../../../application/store/auth.store";
import { TimePicker } from "antd";
import { TableHourTypeCustom } from "../partials/TableHourTypeCustom";
import { uniq } from "lodash";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const schema = yup.object().shape({
    name: yup.string().required(),
});

interface PropsAgenda {
    agenda?: any;
    handleCloseModal?: any;
}

export const AddAgenda = ({ agenda, handleCloseModal }: PropsAgenda) => {
    const alertSection: any = useRef(null);
    const user = useAuthStore((state) => state.user);
    const mairies = useMairieStore((state) => state.mairies);
    const agents = useMairieStore((state) => state.agents);
    const motifs = useMairieStore((state) => state.motifs);
    const agendas = useMairieStore((state) => state.agendas);
    const [daysExceptions, setDaysExceptions] = useState<any>(
        agenda?.daysExceptions
    );
    const [busyDays, setBusyDays] = useState<any>(agenda?.busyDays);
    const [excludeDefaultHour, setExcludeDefaultHour] = useState([
        { startDate: "", endDate: "" },
    ]);
    const [excludeCustomHour, setExcludeCustomHour] = useState([
        { startDate: "", endDate: "" },
    ]);
    const [includeCustomHours, setIncludeCustomHours] = useState({
        startDate: "",
        endDate: "",
    });
    const [includeExceptionHours, setIncludeExceptionHours] = useState({
        startDate: "",
        endDate: "",
    });
    const [selectedMotifs, setSelectedMotifs] = useState<any>(
        agenda?.motifs ?? []
    );
    const [defaultDayExept, setDefaultDayExept] = useState<any>({
        day: dayjs().format("DD/MM/YYYY"),
        startHourOne: "00:00",
        endHourOne: "12:00",
        startHourTwo: "13:00",
        endHourTwo: "23:00",
    });
    const [busyDay, setBusyDay] = useState<any>({
        day: dayjs(),
        startHour: "00:00",
        endHour: "12:00",
    });

    const [error, setError] = useState<String>("");
    const [success, setSuccess] = useState<Boolean>(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
        watch,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });
    const [typeIncludeCustomHours, setTypeIncludeCustomHours] =
        useState("year");
    const [accessAgent, setAccessAgent] = useState<any[]>(agenda?.access ?? []);
    const [mairieParent, setMairieParent] = useState<any>(null);
    const [calendarWeekDefault, setCalendarWeekDefault] = useState(
        agenda?.mairieHours
            ? agenda?.mairieHours
            : mairies[0]?.calendar.hours
            ? mairies[0]?.calendar.hours
            : initialCalendar
    );
    const [calendarWeekCustom, setCalendarWeekCustom] = useState(
        agenda?.customHours ? agenda.customHours : initialCalendarCustom
    );
    const [calendarWeekException, setCalendarWeekException] = useState(
        agenda?.exceptionHours ? agenda?.exceptionHours : initialCalendarCustom
    );
    const listMairies = useMemo(() => {
        const array = [...mairies];
        if (mairies[0]?.annexes) {
            return array.concat(mairies[0]?.annexes);
        }
        return array;
    }, [mairies]);

    const limitedAgenda = useMemo(() => {
        if (Number(mairies[0]?.agenda) >= agendas.length) {
            return true;
        } else {
            return false;
        }
    }, [mairies, agendas]);

    useEffect(() => {
        const parent = mairies.find((data) => data._id === agenda?.mairie._id);
        if (parent) {
            setMairieParent(parent);
        } else {
            if (mairies[0]?.annexes) {
                const children = mairies[0]?.annexes.find(
                    (data) => data._id === agenda?.mairie._id
                );
                if (children) {
                    setMairieParent(children);
                }
            }
        }
    }, [agenda, mairies]);

    useEffect(() => {
        setValue("exception_hours", calendarWeekException);
    }, [calendarWeekException, setValue]);

    useEffect(() => {
        if (includeCustomHours?.startDate || includeCustomHours?.endDate) {
            setTypeIncludeCustomHours("before");
        }
    }, [includeCustomHours, setValue]);

    useEffect(() => {
        setValue("custom_hours", calendarWeekCustom);
    }, [calendarWeekCustom, setValue]);

    const handleChangeTypeInclude = (e) => {
        setTypeIncludeCustomHours(e.target.value);
    };

    useEffect(() => {
        if (typeIncludeCustomHours === "year") {
            setIncludeCustomHours({ startDate: "", endDate: "" });
        }
    }, [typeIncludeCustomHours]);

    useEffect(() => {
        if (agenda && agenda?.mairieHours) {
            setCalendarWeekDefault(agenda?.mairieHours);
        } else if (mairies?.length > 0) {
            setCalendarWeekDefault(mairies[0]?.calendar.hours);
        }
    }, [agenda, mairies]);

    useEffect(() => {
        if (agenda) {
            setExcludeCustomHour(agenda?.excludeCustomHours ?? []);
            setExcludeDefaultHour(agenda?.excludeDefaultHours ?? []);
            setIncludeCustomHours(agenda?.includeCustomHours);
            setIncludeExceptionHours(agenda?.includeExceptionHours);
            if (
                agenda?.includeCustomHours &&
                agenda?.includeCustomHours?.startDate
            ) {
                setTypeIncludeCustomHours("before");
            }
        }
    }, [agenda]);

    const handleChangeDataSelected = (items) => {
        const data: any = xor(items, selectedMotifs);
        selectedMotifs.map((item: any) => {
            if (item._id === data[0]._id) {
                remove(items, function (e: any) {
                    return e._id === data[0]._id;
                });
            }
        });
        setSelectedMotifs(items);
    };
    
    const handleChangeHour = (data, type, index, callback) => {
        let startOrEnd = type;
        const typeHour = type.includes("2") ? 1 : 0;
        if (typeHour === 1) {
            startOrEnd = type === "startHour2" ? "startHour" : "endHour";
        }

        callback((prevState) => {
            const newState = prevState[index].map((obj, id) => {
                // 👇️ if id equals 2, update country property
                if (id === typeHour) {
                    if (data) {
                        return {
                            ...obj,
                            [startOrEnd]: dayjs(data).format("HH:mm"),
                        };
                    } else {
                        return { ...obj, [startOrEnd]: "-" };
                    }
                } else {
                    return obj;
                }
            });
            return { ...prevState, [index]: newState };
        });
    };

    const handleChangeExcludeDefault = (newData, key, index) => {
        const data = excludeDefaultHour[index];
        const day = (dayjs(newData) as any)
            .utc()
            .local()
            .format()
            .split("+")[0];

        data[key] = day;
        setExcludeDefaultHour((current) =>
            current.map((date, key) => {
                if (index === key) {
                    return data;
                } else {
                    return date;
                }
            })
        );
    };

    const handleChangeExcludeCustom = (newData, key, index) => {
        const data = excludeCustomHour[index];
        const day = (dayjs(newData) as any)
            .utc()
            .local()
            .format()
            .split("+")[0];

        data[key] = day;
        setExcludeCustomHour((current) =>
            current.map((date, key) => {
                if (index === key) {
                    return data;
                } else {
                    return date;
                }
            })
        );
    };

    const handleChangeIncludeCustomHours = (newData, key) => {
        const day = (dayjs(newData) as any)
            .utc()
            .local()
            .format()
            .split("+")[0];

        setIncludeCustomHours((current) => ({ ...current, [key]: day }));
    };

    const handleChangeIncludeExceptionHours = (newData, key) => {
        const day = (dayjs(newData) as any)
            .utc()
            .local()
            .format()
            .split("+")[0];

        setIncludeExceptionHours((current) => ({ ...current, [key]: day }));
    };

    const addExcludeDefaultHour = () => {
        setExcludeDefaultHour((current) => [
            ...current,
            {
                startDate: "",
                endDate: "",
            },
        ]);
    };

    const addExludeCustomHour = () => {
        setExcludeCustomHour((current) => [
            ...current,
            {
                startDate: "",
                endDate: "",
            },
        ]);
    };
    const onSubmitHandler = async (data) => {
        setSuccess(false);
        setError("");
        let result: any = null;
        const input = {
            mairie: mairieParent._id,
            name: data.name,
            motifs: selectedMotifs.map((selected) => selected?._id),
            mairieHours: calendarWeekDefault,
            defaultHours: data.principal_hour_mairie,
            defaultShow: data.defaultShow,
            checkedExceptionHours: data.exception_hour_mairie ? true : false,
            checkedCustomHours: data.custom_hour_mairie ? true : false,
            excludeDefaultHours: excludeDefaultHour,
            customHours: calendarWeekCustom,
            includeCustomHours: includeCustomHours,
            excludeCustomHours: excludeCustomHour,
            exceptionHours: calendarWeekException,
            includeExceptionHours: includeExceptionHours,
            daysExceptions: daysExceptions,
            busyDays: busyDays,
            exceptionDayHour: data.exception_day_hour_mairie ? true : false,
            access:
                data?.access_agent === "all_agent"
                    ? []
                    : accessAgent?.length > 0
                    ? uniq(accessAgent.map((agent) => agent?._id))
                    : [],
        };

        if (agenda?._id) {
            result = await handleUpdateAgenda({
                ...input,
                _id: agenda._id,
            });
        } else {
            result = await handleAddgenda(input);
        }
        if (result?.success) {
            refetchListAgendas({ mairies: mairies, user: user });
            setSuccess(true);
        } else {
            setError(
                agenda?._id
                    ? "Une erreur est survenue lors du mis à jour"
                    : "Une erreur est survenue lors de l'ajout"
            );
        }
        alertSection?.current?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
        });
    };

    const handleRemoveExcludeHour = (newData, setData) => {
        setData((days) =>
            days.filter((object) => {
                return (
                    object.startDate !== newData.startDate &&
                    object.endDate !== newData.endDate
                );
            })
        );
    };

    const customHours = watch("custom_hours");
    const exceptionHours = watch("exception_hours");

    const hanldeAddDayException = () => {
        const temp = {
            [dayjs(defaultDayExept?.day).format("DD/MM/YYYY")]: [
                {
                    startHour: defaultDayExept?.startHourOne,
                    endHour: defaultDayExept?.endHourOne,
                },
                {
                    startHour: defaultDayExept?.startHourTwo,
                    endHour: defaultDayExept?.endHourTwo,
                },
            ],
        };

        setDaysExceptions((prevState) => ({ ...prevState, ...temp }));
    };

    const hanldeAddBusyDay = () => {
        const temp = {
            [dayjs(busyDay?.day).format("DD/MM/YYYY")]: [
                { startHour: busyDay?.startHour, endHour: busyDay?.endHour },
            ],
        };
        setBusyDays((prevState) => ({ ...prevState, ...temp }));
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid
                item
                xs={12}
                marginTop={agenda?._id ? 0 : 2}
                ref={alertSection}
            >
                {success && (
                    <Alert severity="success">
                        {agenda?._id
                            ? "Agenda mis à jour avec succès!"
                            : "Agenda ajouté avec succès!"}
                    </Alert>
                )}
                {error && <Alert severity="error">{error}</Alert>}
            </Grid>
            {limitedAgenda ? (
                <Box className="!mt-0 box-admin ">
                    <Stack gap={2} className="padding-left-right">
                        <Stack>
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        className="blue-label"
                                        control={
                                            <Checkbox
                                                defaultChecked={
                                                    agenda?.defaultShow
                                                }
                                                {...register("defaultShow")}
                                            />
                                        }
                                        label="Affichage de l'agenda par défaut"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Stack>
                        <Stack>
                            <Paper className="custom-paper">
                                <FormControl className="custom-form-control">
                                    <OutlinedInput
                                        className={"bg-grey"}
                                        placeholder={"Nom l'agenda*"}
                                        defaultValue={agenda?.name}
                                        {...register("name")}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                {!!errors.name ? (
                                                    <CancelRounded
                                                        color={"error"}
                                                    />
                                                ) : (
                                                    !!getValues("name") && (
                                                        <CheckCircle
                                                            color={"secondary"}
                                                        />
                                                    )
                                                )}
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Paper>
                        </Stack>
                        <Stack>
                            <Paper className="custom-paper">
                                <Autocomplete
                                    disableClearable
                                    options={listMairies}
                                    getOptionLabel={(option) => {
                                        if (option["name"])
                                            return option["name"];
                                        if (typeof option === "string")
                                            return option;
                                        return "";
                                    }}
                                    value={mairieParent}
                                    onChange={(event: any, newValue: any) => {
                                        setMairieParent(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    paddingRight:
                                                        "10px!important",
                                                },
                                            }}
                                            placeholder={"Mairie Principale*"}
                                        />
                                    )}
                                />
                            </Paper>
                        </Stack>
                        <Stack>
                            <Paper className="custom-paper">
                                <SelectMultiple
                                    selectedValues={selectedMotifs}
                                    listData={motifs ?? []}
                                    handleChangeDataSelected={
                                        handleChangeDataSelected
                                    }
                                />
                            </Paper>
                        </Stack>
                        <p className="bold-title">Paramétrage horaires</p>
                        {!watch("custom_hour_mairie") && (
                            <Stack>
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            className="blue-label"
                                            control={
                                                <Checkbox
                                                    defaultChecked={
                                                        agenda?.defaultHours
                                                    }
                                                    {...register(
                                                        "principal_hour_mairie"
                                                    )}
                                                />
                                            }
                                            label="RDV disponibles aux horaires d'ouverture de la mairie"
                                        />
                                    </FormGroup>
                                </FormControl>
                                <div className="bloc-hour disableDiv">
                                    <CalendarWeek
                                        calendarWeek={calendarWeekDefault}
                                        handleChangeHour={() => {}}
                                        isDisabled={true}
                                    />
                                </div>
                                <div className="text-disabled-hour">
                                    <p className="uppercase mb-0">
                                        Non modifiable
                                    </p>
                                </div>
                                <Stack marginTop={2}>
                                    <div className="flex gap-3">
                                        <p className="mt-2">Sauf</p>
                                        <Stack>
                                            {excludeDefaultHour.map(
                                                (dayMairie, index) => (
                                                    <Stack
                                                        key={index}
                                                        direction={"row"}
                                                        gap={2}
                                                        marginBottom={1}
                                                        alignItems={"center"}
                                                    >
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDateFns
                                                            }
                                                            adapterLocale={
                                                                frLocale
                                                            }
                                                        >
                                                            <DesktopDatePicker
                                                                value={
                                                                    dayMairie[
                                                                        "startDate"
                                                                    ]
                                                                }
                                                                minDate={dayjs()}
                                                                onChange={(
                                                                    newValue
                                                                ) => {
                                                                    handleChangeExcludeDefault(
                                                                        newValue,
                                                                        "startDate",
                                                                        index
                                                                    );
                                                                }}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        {...params}
                                                                        size="small"
                                                                        sx={{
                                                                            width: 200,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                        à
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDateFns
                                                            }
                                                            adapterLocale={
                                                                frLocale
                                                            }
                                                        >
                                                            <DesktopDatePicker
                                                                value={
                                                                    dayMairie[
                                                                        "endDate"
                                                                    ]
                                                                }
                                                                minDate={dayjs()}
                                                                onChange={(
                                                                    newValue
                                                                ) => {
                                                                    handleChangeExcludeDefault(
                                                                        newValue,
                                                                        "endDate",
                                                                        index
                                                                    );
                                                                }}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        {...params}
                                                                        size="small"
                                                                        sx={{
                                                                            width: 200,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                        {index > 0 && (
                                                            <DeleteIcon
                                                                className="cursor-pointer"
                                                                sx={{
                                                                    color: "red",
                                                                    fontSize:
                                                                        "20px",
                                                                }}
                                                                onClick={() =>
                                                                    handleRemoveExcludeHour(
                                                                        dayMairie,
                                                                        setExcludeDefaultHour
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </Stack>
                                                )
                                            )}
                                        </Stack>
                                        <AddCircleIcon
                                            className="cursor-pointer mt-2"
                                            sx={{ color: "#44c644" }}
                                            onClick={() =>
                                                addExcludeDefaultHour()
                                            }
                                        />
                                    </div>
                                </Stack>
                                <Stack>
                                    <p>Aperçu horaires</p>
                                    <TableContainer
                                        component={Paper}
                                        className="mt-3"
                                    >
                                        <Table
                                            sx={{ minWidth: 700 }}
                                            size="small"
                                            aria-label="spanning table"
                                        >
                                            <TableHead className="bg-grey uppercase">
                                                <TableRow>
                                                    <TableCell
                                                        align="left"
                                                        colSpan={6}
                                                    >
                                                        Créneaux horaires mairie
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {calendarWeekDefault &&
                                                    Object.keys(
                                                        calendarWeekDefault
                                                    )?.map((key) =>
                                                        calendarWeekDefault[
                                                            key
                                                        ].map(
                                                            (hours, index) => {
                                                                if (
                                                                    hours.startHour !==
                                                                        "-" &&
                                                                    hours.endHour !==
                                                                        "-"
                                                                ) {
                                                                    return (
                                                                        <TableHourType
                                                                            index={
                                                                                index
                                                                            }
                                                                            key={`${key}-${index}`}
                                                                            day={
                                                                                key
                                                                            }
                                                                            hours={
                                                                                hours
                                                                            }
                                                                            includeHour={
                                                                                null
                                                                            }
                                                                            excludesHour={
                                                                                excludeDefaultHour
                                                                            }
                                                                            callback={
                                                                                setCalendarWeekDefault
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                            }
                                                        )
                                                    )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Stack>
                            </Stack>
                        )}
                        {!watch("principal_hour_mairie") && (
                            <Stack marginTop={2}>
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            className="blue-label"
                                            control={
                                                <Checkbox
                                                    defaultChecked={
                                                        agenda?.checkedCustomHours
                                                    }
                                                    {...register(
                                                        "custom_hour_mairie"
                                                    )}
                                                />
                                            }
                                            label="Créneaux horaires personalisés"
                                        />
                                    </FormGroup>
                                </FormControl>
                                <div className="bloc-hour">
                                    <CalendarWeek
                                        calendarWeek={calendarWeekCustom}
                                        handleChangeHour={(data, type, index) =>
                                            handleChangeHour(
                                                data,
                                                type,
                                                index,
                                                setCalendarWeekCustom
                                            )
                                        }
                                        checkBox={true}
                                    />
                                </div>
                                <Stack marginTop={2}>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <p className="bold-title mt-2">
                                                Applicables :
                                            </p>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="applicable"
                                                    value={
                                                        typeIncludeCustomHours
                                                    }
                                                    onChange={
                                                        handleChangeTypeInclude
                                                    }
                                                    className="flex-col"
                                                >
                                                    <FormControlLabel
                                                        value="year"
                                                        control={<Radio />}
                                                        label="Une année"
                                                    />
                                                    <div className="flex items-center">
                                                        <FormControlLabel
                                                            value="before"
                                                            control={<Radio />}
                                                            label="De  "
                                                        />
                                                        <div className="flex items-center gap-3">
                                                            <LocalizationProvider
                                                                dateAdapter={
                                                                    AdapterDateFns
                                                                }
                                                                adapterLocale={
                                                                    frLocale
                                                                }
                                                            >
                                                                <DesktopDatePicker
                                                                    value={
                                                                        includeCustomHours[
                                                                            "startDate"
                                                                        ]
                                                                    }
                                                                    minDate={dayjs()}
                                                                    onChange={(
                                                                        newValue
                                                                    ) => {
                                                                        handleChangeIncludeCustomHours(
                                                                            newValue,
                                                                            "startDate"
                                                                        );
                                                                    }}
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            {...params}
                                                                            size="small"
                                                                            sx={{
                                                                                width: 150,
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                            à
                                                            <LocalizationProvider
                                                                dateAdapter={
                                                                    AdapterDateFns
                                                                }
                                                                adapterLocale={
                                                                    frLocale
                                                                }
                                                            >
                                                                <DesktopDatePicker
                                                                    value={
                                                                        includeCustomHours[
                                                                            "endDate"
                                                                        ]
                                                                    }
                                                                    minDate={dayjs()}
                                                                    onChange={(
                                                                        newValue
                                                                    ) => {
                                                                        handleChangeIncludeCustomHours(
                                                                            newValue,
                                                                            "endDate"
                                                                        );
                                                                    }}
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            {...params}
                                                                            size="small"
                                                                            sx={{
                                                                                width: 150,
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </div>
                                                    </div>
                                                </RadioGroup>
                                            </FormControl>
                                            <div className="flex gap-3 mt-2 ml-6">
                                                <p className="mt-2">Sauf</p>
                                                <Stack>
                                                    {excludeCustomHour.map(
                                                        (dateHours, index) => (
                                                            <Stack
                                                                key={index}
                                                                direction={
                                                                    "row"
                                                                }
                                                                gap={2}
                                                                marginBottom={1}
                                                                alignItems={
                                                                    "center"
                                                                }
                                                            >
                                                                <LocalizationProvider
                                                                    dateAdapter={
                                                                        AdapterDateFns
                                                                    }
                                                                    adapterLocale={
                                                                        frLocale
                                                                    }
                                                                >
                                                                    <DesktopDatePicker
                                                                        value={
                                                                            dateHours[
                                                                                "startDate"
                                                                            ]
                                                                        }
                                                                        minDate={dayjs()}
                                                                        onChange={(
                                                                            newValue
                                                                        ) => {
                                                                            handleChangeExcludeCustom(
                                                                                newValue,
                                                                                "startDate",
                                                                                index
                                                                            );
                                                                        }}
                                                                        renderInput={(
                                                                            params
                                                                        ) => (
                                                                            <TextField
                                                                                {...params}
                                                                                size="small"
                                                                                sx={{
                                                                                    width: 200,
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                                à
                                                                <LocalizationProvider
                                                                    dateAdapter={
                                                                        AdapterDateFns
                                                                    }
                                                                    adapterLocale={
                                                                        frLocale
                                                                    }
                                                                >
                                                                    <DesktopDatePicker
                                                                        value={
                                                                            dateHours[
                                                                                "endDate"
                                                                            ]
                                                                        }
                                                                        minDate={dayjs()}
                                                                        onChange={(
                                                                            newValue
                                                                        ) => {
                                                                            handleChangeExcludeCustom(
                                                                                newValue,
                                                                                "endDate",
                                                                                index
                                                                            );
                                                                        }}
                                                                        renderInput={(
                                                                            params
                                                                        ) => (
                                                                            <TextField
                                                                                {...params}
                                                                                size="small"
                                                                                sx={{
                                                                                    width: 200,
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                                {index > 0 && (
                                                                    <DeleteIcon
                                                                        className="cursor-pointer"
                                                                        sx={{
                                                                            color: "red",
                                                                            fontSize:
                                                                                "20px",
                                                                        }}
                                                                        onClick={() =>
                                                                            handleRemoveExcludeHour(
                                                                                dateHours,
                                                                                setExcludeCustomHour
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            </Stack>
                                                        )
                                                    )}
                                                </Stack>
                                                <AddCircleIcon
                                                    className="cursor-pointer mt-2"
                                                    sx={{ color: "#44c644" }}
                                                    onClick={() =>
                                                        addExludeCustomHour()
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Stack>
                                <Stack>
                                    <p>Aperçu horaires</p>
                                    <TableContainer
                                        component={Paper}
                                        className="mt-3"
                                    >
                                        <Table
                                            sx={{ minWidth: 700 }}
                                            size="small"
                                            aria-label="spanning table"
                                        >
                                            <TableHead className="bg-grey uppercase">
                                                <TableRow>
                                                    <TableCell
                                                        align="left"
                                                        colSpan={6}
                                                    >
                                                        Créneaux horaires
                                                        personalisé
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {customHours &&
                                                    Object.keys(
                                                        customHours
                                                    )?.map((key) =>
                                                        customHours[key].map(
                                                            (hours, index) => {
                                                                if (
                                                                    hours.startHour !==
                                                                        "-" &&
                                                                    hours.endHour !==
                                                                        "-"
                                                                ) {
                                                                    return (
                                                                        <TableHourType
                                                                            index={
                                                                                index
                                                                            }
                                                                            key={`${index}-${key}`}
                                                                            day={
                                                                                key
                                                                            }
                                                                            hours={
                                                                                hours
                                                                            }
                                                                            includeHour={
                                                                                includeCustomHours
                                                                            }
                                                                            excludesHour={
                                                                                excludeCustomHour
                                                                            }
                                                                            callback={
                                                                                setCalendarWeekCustom
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                            }
                                                        )
                                                    )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Stack>
                            </Stack>
                        )}
                        <Stack marginTop={2}>
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value="exception_hour_mairie"
                                        className="blue-label"
                                        control={
                                            <Checkbox
                                                defaultChecked={
                                                    agenda?.checkedExceptionHours
                                                }
                                                {...register(
                                                    "exception_hour_mairie"
                                                )}
                                            />
                                        }
                                        label="Créneaux horaires exceptionnels"
                                    />
                                </FormGroup>
                            </FormControl>
                            <div className="bloc-hour">
                                <CalendarWeek
                                    calendarWeek={calendarWeekException}
                                    handleChangeHour={(data, type, index) =>
                                        handleChangeHour(
                                            data,
                                            type,
                                            index,
                                            setCalendarWeekException
                                        )
                                    }
                                    checkBox={true}
                                />
                            </div>
                            <Stack marginTop={2}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <p className="bold-title mt-2">
                                            Applicable :
                                        </p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="applicable"
                                                {...register(
                                                    "include-exception"
                                                )}
                                                defaultValue="include-exception"
                                                className="flex-col"
                                            >
                                                <div className="flex items-center">
                                                    <FormControlLabel
                                                        value="include-exception"
                                                        control={<Radio />}
                                                        label="De  "
                                                    />
                                                    <div className="flex items-center gap-3">
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDateFns
                                                            }
                                                            adapterLocale={
                                                                frLocale
                                                            }
                                                        >
                                                            <DesktopDatePicker
                                                                value={dayjs(
                                                                    includeExceptionHours[
                                                                        "startDate"
                                                                    ]
                                                                )}
                                                                minDate={dayjs()}
                                                                onChange={(
                                                                    newValue
                                                                ) => {
                                                                    handleChangeIncludeExceptionHours(
                                                                        newValue,
                                                                        "startDate"
                                                                    );
                                                                }}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        {...params}
                                                                        size="small"
                                                                        sx={{
                                                                            width: 150,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                        à
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDateFns
                                                            }
                                                            adapterLocale={
                                                                frLocale
                                                            }
                                                        >
                                                            <DesktopDatePicker
                                                                value={dayjs(
                                                                    includeExceptionHours[
                                                                        "endDate"
                                                                    ]
                                                                )}
                                                                minDate={dayjs()}
                                                                onChange={(
                                                                    newValue
                                                                ) => {
                                                                    handleChangeIncludeExceptionHours(
                                                                        newValue,
                                                                        "endDate"
                                                                    );
                                                                }}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        {...params}
                                                                        size="small"
                                                                        sx={{
                                                                            width: 150,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Stack>
                        <Stack>
                            <p>Aperçu horaires</p>

                            <TableContainer component={Paper} className="mt-5">
                                <Table
                                    sx={{ minWidth: 700 }}
                                    size="small"
                                    aria-label="spanning table"
                                >
                                    <TableHead className="bg-grey uppercase">
                                        <TableRow>
                                            <TableCell align="left" colSpan={4}>
                                                Créneaux horaires exceptionnels
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {exceptionHours &&
                                            Object.keys(exceptionHours)?.map(
                                                (key) =>
                                                    exceptionHours[key].map(
                                                        (hours, index) => {
                                                            if (
                                                                hours[
                                                                    "startHour"
                                                                ] !== "-" &&
                                                                hours[
                                                                    "endHour"
                                                                ] !== "-"
                                                            ) {
                                                                return (
                                                                    <TableHourType
                                                                        index={
                                                                            index
                                                                        }
                                                                        key={`${index}-${key}`}
                                                                        day={
                                                                            key
                                                                        }
                                                                        hours={
                                                                            hours
                                                                        }
                                                                        includeHour={
                                                                            includeExceptionHours
                                                                        }
                                                                        excludesHour={
                                                                            null
                                                                        }
                                                                        callback={
                                                                            setCalendarWeekException
                                                                        }
                                                                    />
                                                                );
                                                            }
                                                        }
                                                    )
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                        <Stack marginTop={2}>
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value="exception_day_hour_mairie"
                                        className="blue-label"
                                        control={
                                            <Checkbox
                                                defaultChecked={
                                                    agenda?.exceptionDayHour
                                                }
                                                {...register(
                                                    "exception_day_hour_mairie"
                                                )}
                                            />
                                        }
                                        label='Créneaux "ouverture exceptionnelle"'
                                    />
                                </FormGroup>
                            </FormControl>
                            <Stack className="mt-2">
                                <Grid container>
                                    <Grid item flexDirection={"row"} xs={12}>
                                        <div className="flex items-center gap-3">
                                            <p>Le </p>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                                adapterLocale={frLocale}
                                            >
                                                <DesktopDatePicker
                                                    value={dayjs(
                                                        defaultDayExept?.day
                                                    )}
                                                    inputFormat={"dd/MM/yyyy"}
                                                    onChange={(newValue: any) =>
                                                        setDefaultDayExept(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                day: newValue,
                                                            })
                                                        )
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            sx={{ width: 150 }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                            <p>Matin: </p>
                                            <Stack spacing={1}>
                                                <TimePicker
                                                    format={"HH:mm"}
                                                    className="input-time-picker"
                                                    placeholder="-"
                                                    value={
                                                        defaultDayExept?.startHourOne?.split(
                                                            ":"
                                                        )[1]
                                                            ? (dayjs()
                                                                  .hour(
                                                                      (defaultDayExept?.startHourOne?.split(
                                                                          ":"
                                                                      ))[0] ?? 7
                                                                  )
                                                                  .minute(
                                                                      (defaultDayExept?.startHourOne?.split(
                                                                          ":"
                                                                      ))[1] ?? 0
                                                                  ) as any)
                                                            : null
                                                    }
                                                    onChange={(
                                                        newValue: any
                                                    ) => {
                                                        setDefaultDayExept(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                startHourOne:
                                                                    newValue
                                                                        ? dayjs(
                                                                              newValue
                                                                          ).format(
                                                                              "HH:mm"
                                                                          )
                                                                        : "-",
                                                            })
                                                        );
                                                    }}
                                                />
                                            </Stack>
                                            <p> à </p>
                                            <Stack spacing={1}>
                                                <TimePicker
                                                    format={"HH:mm"}
                                                    className="input-time-picker"
                                                    placeholder="-"
                                                    value={
                                                        defaultDayExept?.endHourOne?.split(
                                                            ":"
                                                        )[1]
                                                            ? (dayjs()
                                                                  .hour(
                                                                      defaultDayExept?.endHourOne?.split(
                                                                          ":"
                                                                      )[0] ?? 12
                                                                  )
                                                                  .minute(
                                                                      defaultDayExept?.endHourOne?.split(
                                                                          ":"
                                                                      )[1] ?? 0
                                                                  ) as any)
                                                            : null
                                                    }
                                                    onChange={(newValue: any) =>
                                                        setDefaultDayExept(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                endHourOne:
                                                                    newValue
                                                                        ? dayjs(
                                                                              newValue
                                                                          ).format(
                                                                              "HH:mm"
                                                                          )
                                                                        : "-",
                                                            })
                                                        )
                                                    }
                                                />
                                            </Stack>
                                            <p>Soir: </p>
                                            <Stack spacing={1}>
                                                <TimePicker
                                                    format={"HH:mm"}
                                                    className="input-time-picker"
                                                    placeholder="-"
                                                    value={
                                                        defaultDayExept?.startHourTwo?.split(
                                                            ":"
                                                        )[1]
                                                            ? (dayjs()
                                                                  .hour(
                                                                      defaultDayExept?.startHourTwo?.split(
                                                                          ":"
                                                                      )[0] ?? 13
                                                                  )
                                                                  .minute(
                                                                      defaultDayExept?.startHourTwo?.split(
                                                                          ":"
                                                                      )[1] ?? 0
                                                                  ) as any)
                                                            : null
                                                    }
                                                    onChange={(newValue: any) =>
                                                        setDefaultDayExept(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                startHourTwo:
                                                                    newValue
                                                                        ? dayjs(
                                                                              newValue
                                                                          ).format(
                                                                              "HH:mm"
                                                                          )
                                                                        : "-",
                                                            })
                                                        )
                                                    }
                                                />
                                            </Stack>
                                            <p> à </p>
                                            <Stack spacing={1}>
                                                <TimePicker
                                                    format={"HH:mm"}
                                                    className="input-time-picker"
                                                    placeholder="-"
                                                    value={
                                                        defaultDayExept?.endHourTwo?.split(
                                                            ":"
                                                        )[1]
                                                            ? (dayjs()
                                                                  .hour(
                                                                      defaultDayExept?.endHourTwo?.split(
                                                                          ":"
                                                                      )[0] ?? 18
                                                                  )
                                                                  .minute(
                                                                      defaultDayExept?.endHourTwo?.split(
                                                                          ":"
                                                                      )[1] ?? 0
                                                                  ) as any)
                                                            : null
                                                    }
                                                    onChange={(newValue: any) =>
                                                        setDefaultDayExept(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                endHourTwo:
                                                                    newValue
                                                                        ? dayjs(
                                                                              newValue
                                                                          ).format(
                                                                              "HH:mm"
                                                                          )
                                                                        : "-",
                                                            })
                                                        )
                                                    }
                                                />
                                            </Stack>
                                            <AddCircleIcon
                                                className="cursor-pointer"
                                                sx={{ color: "#44c644" }}
                                                onClick={() =>
                                                    hanldeAddDayException()
                                                }
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Stack>
                        <Stack>
                            <p>Aperçu horaires</p>
                            <TableContainer component={Paper} className="mt-5">
                                <Table
                                    sx={{ minWidth: 700 }}
                                    size="small"
                                    aria-label="spanning table"
                                >
                                    <TableHead className="bg-grey uppercase">
                                        <TableRow>
                                            <TableCell align="left" colSpan={4}>
                                                Créneaux "ouverture
                                                exceptionnelle"
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {daysExceptions &&
                                            Object.keys(daysExceptions)?.map(
                                                (key) =>
                                                    daysExceptions[key]?.map(
                                                        (hours, index) => {
                                                            if (
                                                                hours[
                                                                    "startHour"
                                                                ] !== "-" &&
                                                                hours[
                                                                    "endHour"
                                                                ] !== "-"
                                                            ) {
                                                                return (
                                                                    <TableHourTypeCustom
                                                                        index={
                                                                            index
                                                                        }
                                                                        key={`${index}-${key}`}
                                                                        day={
                                                                            key
                                                                        }
                                                                        hours={
                                                                            hours
                                                                        }
                                                                        callback={
                                                                            setDaysExceptions
                                                                        }
                                                                        type="hourExcept"
                                                                    />
                                                                );
                                                            }
                                                        }
                                                    )
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                        <Stack marginTop={2}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <p className="bold-title mt-2">
                                        Créneaux "indisponibilité de service"
                                    </p>
                                </Grid>
                                <Grid item flexDirection={"row"} xs={12}>
                                    <div className="flex items-center gap-3">
                                        <p>Le </p>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            adapterLocale={frLocale}
                                        >
                                            <DesktopDatePicker
                                                value={dayjs(busyDay?.day)}
                                                inputFormat={"dd/MM/yyyy"}
                                                onChange={(newValue: any) =>
                                                    setBusyDay((prevState) => ({
                                                        ...prevState,
                                                        day: newValue,
                                                    }))
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        sx={{ width: 150 }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        <p>de: </p>
                                        <Stack spacing={1}>
                                            <TimePicker
                                                format={"HH:mm"}
                                                className="input-time-picker"
                                                placeholder="-"
                                                value={
                                                    busyDay?.startHour?.split(
                                                        ":"
                                                    )[1]
                                                        ? (dayjs()
                                                              .hour(
                                                                  busyDay?.startHour?.split(
                                                                      ":"
                                                                  )[0] ?? 0
                                                              )
                                                              .minute(
                                                                  busyDay?.startHour?.split(
                                                                      ":"
                                                                  )[1] ?? 0
                                                              ) as any)
                                                        : null
                                                }
                                                onChange={(newValue: any) =>
                                                    setBusyDay((prevState) => ({
                                                        ...prevState,
                                                        startHour: newValue
                                                            ? dayjs(
                                                                  newValue
                                                              ).format("HH:mm")
                                                            : "-",
                                                    }))
                                                }
                                            />
                                        </Stack>
                                        <p> à </p>
                                        <Stack spacing={1}>
                                            <TimePicker
                                                format={"HH:mm"}
                                                className="input-time-picker"
                                                placeholder="-"
                                                value={
                                                    busyDay?.endHour?.split(
                                                        ":"
                                                    )[1]
                                                        ? (dayjs()
                                                              .hour(
                                                                  busyDay?.endHour?.split(
                                                                      ":"
                                                                  )[0] ?? 12
                                                              )
                                                              .minute(
                                                                  busyDay?.endHour?.split(
                                                                      ":"
                                                                  )[1] ?? 0
                                                              ) as any)
                                                        : null
                                                }
                                                onChange={(newValue: any) =>
                                                    setBusyDay((prevState) => ({
                                                        ...prevState,
                                                        endHour: newValue
                                                            ? dayjs(
                                                                  newValue
                                                              ).format("HH:mm")
                                                            : "-",
                                                    }))
                                                }
                                            />
                                        </Stack>
                                        <AddCircleIcon
                                            className="cursor-pointer"
                                            sx={{ color: "#44c644" }}
                                            onClick={() => hanldeAddBusyDay()}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack>
                            <p>Aperçu horaires</p>
                            <TableContainer component={Paper} className="mt-5">
                                <Table
                                    sx={{ minWidth: 700 }}
                                    size="small"
                                    aria-label="spanning table"
                                >
                                    <TableHead className="bg-grey uppercase">
                                        <TableRow>
                                            <TableCell align="left" colSpan={4}>
                                                Créneaux "indisponibilité de
                                                service"
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {busyDays &&
                                            Object.keys(busyDays)?.map((key) =>
                                                busyDays[key].map(
                                                    (hours, index) => {
                                                        if (
                                                            hours[
                                                                "startHour"
                                                            ] !== "-" &&
                                                            hours["endHour"] !==
                                                                "-"
                                                        ) {
                                                            return (
                                                                <TableHourTypeCustom
                                                                    index={
                                                                        index
                                                                    }
                                                                    key={`${index}-${key}`}
                                                                    day={key}
                                                                    hours={
                                                                        hours
                                                                    }
                                                                    callback={
                                                                        setBusyDays
                                                                    }
                                                                    type="busy"
                                                                />
                                                            );
                                                        }
                                                    }
                                                )
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                        <Stack>
                            <Grid container>
                                <Grid item xs={2}>
                                    <p className="bold-title mt-2">
                                        Droits d'accès :
                                    </p>
                                </Grid>
                                <Grid item xs={10}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="applicable"
                                            {...register("access_agent")}
                                            defaultValue={
                                                accessAgent?.length > 0
                                                    ? "agent"
                                                    : "all_agent"
                                            }
                                            className="radio-left-right"
                                        >
                                            <FormControlLabel
                                                value="all_agent"
                                                control={<Radio />}
                                                label="Tous les agents"
                                            />
                                            <div style={{ width: 100 }}>
                                                <FormControlLabel
                                                    value="agent"
                                                    control={<Radio />}
                                                    label="Personalisé"
                                                />
                                                <Stack>
                                                    <Autocomplete
                                                        multiple
                                                        id="agent"
                                                        options={agents}
                                                        noOptionsText="Aucun agent disponible"
                                                        defaultValue={
                                                            accessAgent ?? []
                                                        }
                                                        onChange={(
                                                            event,
                                                            newValue
                                                        ) => {
                                                            setAccessAgent(
                                                                newValue
                                                            );
                                                        }}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(
                                                            option
                                                        ) =>
                                                            `${option.firstName} ${option.lastName}`
                                                        }
                                                        renderOption={(
                                                            props,
                                                            option,
                                                            { selected }
                                                        ) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    icon={icon}
                                                                    checkedIcon={
                                                                        checkedIcon
                                                                    }
                                                                    style={{
                                                                        marginRight: 8,
                                                                    }}
                                                                    checked={
                                                                        selected
                                                                    }
                                                                />
                                                                {`${option.firstName} ${option.lastName}`}
                                                            </li>
                                                        )}
                                                        style={{ width: 300 }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label=""
                                                                placeholder="Sélectionnez des agents"
                                                            />
                                                        )}
                                                    />
                                                </Stack>
                                            </div>
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Stack>
                </Box>
            ) : (
                <Box className="box-admin">
                    <p className="error-limit-agenda">
                        Vous avez atteint le nombre maximum d'agenda à créer.
                    </p>
                </Box>
            )}
            {limitedAgenda && (
                <div className="btn-save-reset padding-left-right">
                    <Button
                        className="btn-next"
                        variant={"contained"}
                        color={"secondary"}
                        startIcon={<SaveIcon />}
                        type="submit"
                    >
                        Enregistrer
                    </Button>
                    <Button
                        className="btn-next"
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => handleCloseModal()}
                    >
                        Annuler
                    </Button>
                </div>
            )}
        </form>
    );
};
